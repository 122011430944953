import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';
import { switchMap, of } from 'rxjs';
import { AuthService } from '../service/auth.service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
	const router: Router = inject(Router);
	const authService: AuthService = inject(AuthService);

	// Check the authentication status
	return authService.check().pipe(
		switchMap((authenticated) => {
			// If the user is not authenticated...
			if (!authenticated) {
				// Redirect to the sign-in page with a redirectUrl param
				// const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
				// const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
				const urlTree = router.parseUrl('/auth/login');

				return of(urlTree);
			}

			// if (authService.hasGeneratedEmail) {
			// 	const urlTree = router.parseUrl('/auth/update-email');
			// 	return of(urlTree);
			// }

			// Allow the access
			return of(true);
		})
	);
};
